/* .loader .icon span {
  display: inline-block;
  height: 15px;
  width: 15px;
  margin: 5px;
  background-color: black;
  border-radius: 15px;
  position: relative;
  animation: zoom 1s ease-in-out infinite;
}
.loader .icon span:nth-child(2) {
  animation-delay: 0.1s
}
.loader .icon span:nth-child(3) {
  animation-delay: 0.2s
}
@keyframes zoom {
  0% {transform: scale(1)}
  50% {transform: scale(0)}
  100% {transform: scale(1)}
} */

div.svg {
  margin-right: 400px;
  margin-bottom: 4000px;
  z-index: 1;
}

::-webkit-scrollbar {
  width: 15px;
  background-color: rgb(211, 53, 53);
}

::-webkit-scrollbar-track {
  border: 7px solid black;
  box-shadow: inset 0 0 2.5px 2px rgb(230, 13, 96);
}
::-webkit-scrollbar-thumb {
  /* Background: linear-gradient(45def, #06dee1, #79ff6c); */
  background-color: rgba(79, 243, 229, 0.65);
  border-radius: 40%;
}
