.thanks {
  font-size: 24px;
  letter-spacing: 2px;
  font-family: "Raleway", sans-serif;
  margin: 50px 50px;
  justify-content: center;
  text-align: center;
  text-shadow: 0 22px 22px rgb(218, 139, 139);
}

.list {
  display: flex;
  object-fit: contain;
  max-width: 100%;
}


.contact-me-span {
  font-size: 42px;
  color: rgb(250, 30, 87);
  font-weight: bold;
  letter-spacing: 2px;
  font-family: "Raleway", sans-serif;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

span.asap-span {
  font-weight: bold;
  font-size: 32px;
  font-style: oblique;
  text-shadow: 0 22px 22px rgb(218, 139, 139);
}

span.anytime {
  font-size: 30px;
  margin-right: 120px;
  color: black;
  font-style: oblique;
}

span.anywhere-span {
  font-size: 30px;
  font-style: oblique;
}

.contact-aa {
  text-align: center;
}

.contact-container {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  margin: 0 auto;
  justify-content: center;
  border-radius: 4px;
  height: 1300px;
  align-content: flex-start;
  max-width: 100%;
  background: url(https://i.imgur.com/YJGJ53d.jpg);
  background-size: 100%;
  background-size: cover;
}

.link-list {
  margin: 0px 60px 0px 60px;
}

.contact-box {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  padding: 20px;
}

@keyframes blink {
  50% {
    background: radial-gradient(ellipse, rgb(230, 54, 39) 5%, transparent 10%),
      rgba(243, 223, 223, 0.8);
  }
}

@-webkit-keyframes blink {
  50% {
    background: -webkit-radial-gradient(ellipse, #fa1e57 5%, transparent 10%),
      rgba(243, 223, 223, 0.8);
  }
}

@-moz-keyframes blink {
  50% {
    background: -moz-radial-gradient(ellipse, #fa1e57 5%, transparent 10%),
      rgba(243, 223, 223, 0.8);
  }
}

div.contact-form {
  width: 70%;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 50%;
  
}

.contact-form {
  border: solid black;
  padding: 40px;
  padding-top: 30px;
  border-radius: 20px;
  box-shadow: 0 0 17px 6px rgba(0, 0, 0, 1);
  animation: blink 1s linear infinite;
  -webkit-animation: blink 1.25s infinite;
  -moz-animation: blink 1.25s infinite;
  background: radial-gradient(ellipse, white 5%, transparent 10%),
    rgba(243, 223, 223, 0.7);
}

.pin-drop {
  width: 15%;
}

.icon-email {
  height: 65px;
  width: 60px;
}

.icon-email:hover {
  opacity: 0.7;
}

.icon-linkedin {
  height: 55px;
  width: 60px;
}

.icon-linkedin:hover {
  opacity: 0.7;
}

.icon-tele {
  height: 65px;
  width: 65px;
  border-radius: 50%;
}

.icon-tele:hover {
  opacity: 0.7;
}

.icon-github {
  height: 65px;
  width: 65px;
}

.icon-github:hover {
  opacity: 0.7;
}

.icon-resume {
  height: 70px;
  width: 75px;
}

.icon-resume:hover {
  opacity: 0.7;
}

.contact-box a:link {
  color: black;
}
.contact-box a:visited {
  color: #fa1e57;
}

div.svg {
  margin-left: 400px;
}

/* Apple MacBook Pro 15-inch (Early 2006 - Mid 2012) AND Apple MacBook Air 13-inch */
@media screen and (max-width: 1440px) {
  div.svg {
    margin-right: 400px;
    z-index: 1;
  }

  /* .contact-box {
    object-fit: contain;
    max-width: 75%;
  } */

  /* .contact-form {
    max-width: 70%;
  } */

  /* .contact-container {
    border-radius: 4px;
    height: 1300px;
    height: 1300px;
    max-width: 100%;
    background-size: 75%;
    background-size: cover;
  } */

  .get-back-span {
    color: black;
    font-size: 24px;
  }

  .asap-span {
    font-weight: bold;
    font-size: 32px;
    font-style: oblique;
    text-shadow: 0 22px 22px rgb(218, 139, 139);
  }

  div.contact-container {
    height: 950px;
    object-fit: contain;
  }

  .contact-me-span {
    font-size: 34px;
  }

  .anytime {
    font-size: 30px;
    color: black;
  }

  span.anywhere-span {
    font-size: 30px;
  }

  .pin-drop {
    height: 100px;
    width: 100px;
    margin-top: -40px;
    margin-left: 20px;
  }
}

/* Macbook Pro 13inch (Mid 2009 - 2012) */
@media screen and (max-width: 1280px) {
  /* .contact-form {
    width: 70%;
  }  */
    /* .contact-box {
    max-width: 70%;
    object-fit: contain;
  }  */
}

/* Ipad Pro */
@media screen and (max-width: 1024px) {
  /* .contact-container {
    border-radius: 4px;
    height: 1800px;
    max-width: 100%;
    background-size: 75%;
    background-size: cover;
  } */

  /* div.contact-box {
    max-width: 70%;
    object-fit: contain;
  } */

  .link-list {
    margin: 0px 30px 0px 30px;
  }
  
  ul.list {
    margin-top: 20px;
    padding: 0px;
  }

  .get-back-span {
    color: black;
    font-size: 22px;
  }

  .asap-span {
    font-weight: bold;
    font-size: 22px;
    font-style: oblique;
    text-shadow: 0 22px 22px rgb(218, 139, 139);
  }

  /* div.contact-container {
    height: 850px;
  } */

  /* ul.contact-box {
    margin-top: -40px;
  } */

  .contact-me-span {
    font-size: 32px;
  }

  .anytime {
    font-size: 26px;
    color: black;
  }

  .pin-drop {
    height: 80px;
    width: 80px;
    margin-top: -40px;
    margin-left: 20px;
  }
}

/* Ipad */
@media screen and (max-width: 768px) {
  .asap-span {
    font-size: 40px;
  }
  .link-list {
    margin: 0px 25px 0px 25px;
  }
  
  ul.list {
    margin-top: 20px;
    padding: 0px;
  }

  img.pin-drop {
    margin-top: 10px;
  }
}
@media screen and (max-width: 414px) {
  div.contact-form {
    width: 70%;
    border-radius: 90px;
    height: 50%;
  }

  span.anytime {
    font-size: 30px;
    margin-right: 80px;
    color: black;
    font-style: oblique;
  }

  .link-list {
    margin: auto;
}

img.pin-drop {
  margin-top: 10px;
}
}
/* Iphone X */
@media screen and (max-width: 375px) {
  span.anytime {
    font-size: 28px;
    color: black;
    font-style: oblique;
  }

  span.anywhere-span {
    font-size: 28px;
    font-style: oblique;
  }

  .get-back-span {
    color: black;
    font-size: 16px;
  }

  span.asap-span {
    font-weight: bold;
    font-size: 24px;
    font-style: oblique;
    text-shadow: 0 22px 22px rgb(218, 139, 139);
  }

  img.pin-drop {
    margin-top: 10px;
  }

  .contact-me-span {
    font-size: 26px;
  }

  .link-list {
    margin: auto;
  }
}

/* Iphone X */
@media screen and (max-width: 320px) {
  span.anytime {
    font-size: 26px;
    color: black;
    font-style: oblique;
    margin: auto;
    padding: 10px;
  }

  span.anywhere-span {
    font-size: 28px;
    font-style: oblique;
    padding: 10px;

  }


  img.pin-drop {
    margin-top: 10px;
  }

  .contact-me-span {
    font-size: 26px;
  }

  .link-list {
    width: 75%;
  
  }

  div.contact-box {
    padding: 0px;
  }
}
