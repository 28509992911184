footer {
  display: flex;
  background-color: rgb(23, 29, 28);
  color: white;
  height: 50px;
  justify-content: center;
  text-align: center;
  letter-spacing: 2px;
  font-family: "Fira Sans", sans-serif;
  font-size: 18px;
  background-repeat: no-repeat;
  background-size: 50%;
  align-items: center;   
}

div.svg {
  margin-bottom: 230px;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, -30px, 0);
  }
}

#VECTORS {
  animation: bounce 0.7s linear;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  transform-origin: center;

  /* animation: float .8s ease-in-out infinite alternate; */
}


/* @keyframes wave {
  from { transform: rotateY(-20deg);} 
    to { transform: rotateY(20deg);}
} 


#Hair {
  animation: wave 0.7s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  transform-origin: top;
} */


.copyright {
  font-size: 18px;
  color: white;
  display: block;
  border: solid black;
  width: 100%;
  background-color: black;
}



.social-list {
  margin: 0px 20px 0px 20px;
}


.icon-social {
  height: 30px;
  width: 30px;
}

.icon-social:hover {
  opacity: 0.7;
}


.social-box {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  padding: 20px;
}



/* Iphone X */
@media screen and (max-width: 375px) {
  footer {
    font-size: 12px;
  
  }
}

@media screen and (max-width: 1440px) {
  .svg {
    margin-right: 400px;
    margin-bottom: 130px;
  }

  .footer-div {
    width: 100%;
    font-size: 18px;
    color: white;
    display: block;
    border: solid black;
    width: 100%;
    background-color: black;
  }
}

@media screen and (max-width: 1280px) {
  .svg {
    margin-right: 600px;
    margin-bottom: 130px;
  }
}

@media screen and (max-width: 768px) {
  .svg {
    margin-right: 450px;
  }
  .icon-social {
    height: 15px;
    width: 15px;
  }
  .social-box {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    padding: 10px;
  }
}

@media screen and (max-width: 414px) {
  /* div.svg {
    display: none;
  } */
}

@media screen and (max-width: 375px) {
  /* .svg {
  width: 75%;
} */
}
