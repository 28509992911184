@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@600;700;900&display=swap);
  
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* .loader .icon span {
  display: inline-block;
  height: 15px;
  width: 15px;
  margin: 5px;
  background-color: black;
  border-radius: 15px;
  position: relative;
  animation: zoom 1s ease-in-out infinite;
}
.loader .icon span:nth-child(2) {
  animation-delay: 0.1s
}
.loader .icon span:nth-child(3) {
  animation-delay: 0.2s
}
@keyframes zoom {
  0% {transform: scale(1)}
  50% {transform: scale(0)}
  100% {transform: scale(1)}
} */

div.svg {
  margin-right: 400px;
  margin-bottom: 4000px;
  z-index: 1;
}

::-webkit-scrollbar {
  width: 15px;
  background-color: rgb(211, 53, 53);
}

::-webkit-scrollbar-track {
  border: 7px solid black;
  box-shadow: inset 0 0 2.5px 2px rgb(230, 13, 96);
}
::-webkit-scrollbar-thumb {
  /* Background: linear-gradient(45def, #06dee1, #79ff6c); */
  background-color: rgba(79, 243, 229, 0.65);
  border-radius: 40%;
}

.projects-name {
  font-size: 200%;
  font-weight: 100;
  letter-spacing: 3px;
  font-family: "Raleway", sans-serif;
  color: rgb(250, 30, 87);
  height: 0px;
  text-align: center;
  border: solid rgb(18, 67, 80);
  text-shadow: 0px 8px 6px rgba(0, 0, 0, 0.4), 0px 16px 26px rgba(0, 0, 0, 0.1),
    0px 28px 44px rgba(0, 0, 0, 0.1);
}

.project-title {
  font-size: 200%;
  font-weight: 300;
  letter-spacing: 3px;
  font-family: "Raleway", sans-serif;
  color: white;
}

.all-projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 40px;
  border: black;
  margin: 0 auto;
  height: auto;
}

.all-projects {
  background: url(https://i.imgur.com/8MHUS6E.jpg) no-repeat center fixed;
  background-size: cover;
}

.project-img {
  display: flex;
  padding: 15px;
  margin: 0 45px;
  border: solid 2px black;
  height: 500px;
  width: 500px;
  background-color: rgb(80, 235, 235);
  border-radius: 50%;
  
}

.project-img-instacart {
  display: flex;
  padding: 15px;
  border: solid 2px black;
  height: 500px;
  width: 500px;
  background-color: rgb(80, 235, 235);
  border-radius: 50%;
}

a {
  text-decoration: none;
}

a.button {
  font-size: 120%;
}

.mm-project-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  justify-content: center;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
  /* background-color: rgba(245, 201, 201, 0.5); */
}

.mm-project-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.mm-project-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.mm-project-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}

.instabook-project-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 25%;
}

.amazon-project-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 25%;
}

.amazon-project-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.amazon-project-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.amazon-project-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}

.instabook-project-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.instabook-project-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.instabook-project-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}





.instacart-project-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
}

.instacart-project-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.instacart-project-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.instacart-project-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}




.restaurant-demo-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
}

.restaurant-demo-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.restaurant-demo-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.restaurant-demo-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}



.insurance-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
}

.insurance-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.insurance-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.insurance-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}



.chiro-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
}

.chiro-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.chiro-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.chiro-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}



.atny-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
}

.atny-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.atny-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.atny-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}




.portfolio-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
}

.portfolio-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.portfolio-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.portfolio-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}





.ins-landing-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
}

.ins-landing-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.ins-landing-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.ins-landing-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}







.project-description {
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  font-family: "Raleway", sans-serif;
  text-align: center;
  font-size: 20px;
  margin: 20px;
  color: white;
  line-height: 1.5;
  width: 75%;
}

.tech-description {
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  font-family: "Raleway", sans-serif;
  text-align: center;
  font-size: 18px;
  margin: 20px;
  color: white;
}

/* .buttons {
  display: flex;
  justify-content: center;
  width: 85%
};  */

.project-url {
  /* margin-right: 20px; */
  background-color: rgb(80, 235, 235);
  height: 50px;
  width: 175px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-url:hover {
  background-color: rgb(250, 30, 87);
}

.github-url {
  /* margin-left: 20px; */
  background-color: rgb(80, 235, 235);
  height: 50px;
  width: 175px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.github-url:hover {
  background-color: rgb(250, 30, 87);
}

.github-url,
.projecturl a:link {
  color: blue;
}
.github-url,
.projecturl a:visited {
  color: purple;
}

/* Apple MacBook Pro 15-inch (Early 2006 - Mid 2012) AND Apple MacBook Air 13-inch */
@media screen and (max-width: 1440px), screen and (max-height: 900px) {
  /* .all-projects { 
    background: url(https://i.imgur.com/8MHUS6E.jpg) no-repeat center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  } */
}

/* Apple MacBook Pro 13-inch  */
@media screen and (max-width: 1280px) {
  .project-img {
    padding: 15px;
    margin: 0 45px;
    height: 450px;
    width: 450px;
  }

  .project-img-instacart {
    height: 450px;
    width: 450px;
  }

  .project-description {
    letter-spacing: 0.2px;
    font-size: 20px;
    margin: 20px;
    line-height: 1.2;
  }

  .tech-description {
    letter-spacing: 1px;
    font-size: 18px;
    margin: 20px;
    margin-bottom: -30px;
  }
}

/* Ipad Pro */
@media screen and (max-width: 1024px) {
  .project-img {
    height: 350px;
    width: 350px;
  }

  .project-img-instacart {
    height: 350px;
    width: 350px;
  }

  div.buttons {
    margin: auto;
  }
}

/* Ipad */
@media screen and (max-width: 768px) /*, screen and (max-height: 1024px) */ {
  .all-projects {
    background: black;
    background-size: cover;
  }
  .instacart-project-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;
    background-color: rgba(33, 175, 194, 0.25);
  }

  .mm-project-container {
    border: 2px solid rgb(83, 199, 219);
    width: 53%;
    background-color: rgba(33, 175, 194, 0.25);
    /* background-color: rgba(245, 201, 201, 0.5); */
  }

  .instabook-project-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;

    background-color: rgba(33, 175, 194, 0.25);
  }

  .portfolio-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;

    background-color: rgba(33, 175, 194, 0.25);
  }

  .chiro-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;

    background-color: rgba(33, 175, 194, 0.25);
  }

  .atny-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;

    background-color: rgba(33, 175, 194, 0.25);
  }

  .amazon-project-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;
    background-color: rgba(33, 175, 194, 0.25);
  }

  .restaurant-demo-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;
    background-color: rgba(33, 175, 194, 0.25);
  }

  .insurance-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;
    background-color: rgba(33, 175, 194, 0.25);
  }

  .ins-landing-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;
    background-color: rgba(33, 175, 194, 0.25);
  }

  .project-title {
    font-size: 200%;
    font-weight: 300;
    letter-spacing: 3px;
    font-family: "Raleway", sans-serif;
    color: white;
    margin-top: 15px;
  }

  .tech-description {
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-size: 18px;
    margin: 20px;
    margin-bottom: -30px;
  }

  .project-description {
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    margin: 15px;
    margin-bottom: -10px;
    line-height: 1.2;
  }

  .project-img {
    height: 350px;
    width: 350px;
  }

  .project-img-instacart {
    height: 350px;
    width: 350px;
  }
}

/* Iphone 6/7/8 Plus */
@media screen and (max-width: 414px), /* screen and (max-height: 736px) */ {
  .all-projects {
    background: black;
    background-size: cover;
  }

  .buttons {
    width: 200px;
  }
  .buttons {
    margin-top: 10px;
  }

  .project-url {
    margin: auto;
    margin-top: 60px;
    margin-bottom: 20px;
  }

  .github-url {
    margin: auto;
  }

  .img-container {
  
  }

  .project-description {
    justify-content: center;
    text-align: left;
    letter-spacing: 1px;
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    margin: 20px;
    color: white;
    line-height: 1.5;
    width: 75%;
  }

  .projects-name {
    font-size: 24px;
    font-weight: 100;
    letter-spacing: 3px;
    font-family: "Raleway", sans-serif;
    color: rgb(250, 30, 87);
    height: 0px;
    text-align: center;
    border: solid rgb(18, 67, 80);
    text-shadow: 0px 8px 6px rgba(0, 0, 0, 0.4), 0px 16px 26px rgba(0, 0, 0, 0.1),
      0px 28px 44px rgba(0, 0, 0, 0.1);
  }

  .project-title {
    font-size: 22px;
    font-weight: 300;
    letter-spacing: 3px;
    font-family: "Raleway", sans-serif;
    color: white;
  }
}

/* Iphone X */
@media screen and (max-width: 375px) /*, screen and (max-height: 812px) */ {

  .project-img {
    height: 250px;
    width: 250px;
  }

  .project-img-instacart {
    height: 250px;
    width: 250px;
  }

  .project-description {
    justify-content: center;
    text-align: left;
    letter-spacing: 1px;
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    margin: 20px;
    color: white;
    line-height: 1.5;
    width: 75%;
  }

  .tech-description {
    font-size: 16px;
  }

  img.project-img {
    margin: 0px;
  }

  div.buttons {
    margin: auto;
  }
}


/* Iphone 5/SE */
@media screen and (max-width: 320px) {

  .projects-name {
    font-size: 25px;
  }

  /* div.buttons {
    
  } */

  .project-title {
    font-size: 22px;

  }



  .project-url {
    margin-right: 30px;
  }

  .github-url {
    margin-right: 30px;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  height: 100vh;
  justify-content: space-around;
}

.overlay video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  object-position: center;
  object-fit: cover;
}

.main-nav-list {
  padding: 0px;
}

.main-nav ul {
  margin-top: 0px;
  text-align: center;
}

.main-nav li {
  display: inline;
}

.main-nav a {
  display: inline-block;
  padding: 0.3em 8em;
  width: 35%;
  color: white;
  justify-content: space-between;
  font-size: 27px;
  font-weight: 300;
  letter-spacing: 2px;
  font-family: "Raleway", sans-serif;
  justify-content: space-evenly;
  position: static;
  text-decoration: none;
  box-shadow: 0px 12px 10px #26c8ce;
  border-radius: 10px;
}

li a:hover {
  color: rgb(250, 30, 87);
  transition: all 0.3s ease;
}

li a:active {
  background-color: rgba(38, 200, 206, 0.25);
  transform: translateY(15px);
}

.software-title {
  color: white;
  font-family: "Raleway", sans-serif;
  font-weight: heavy;
  letter-spacing: 3px;
  text-align: center;
  font-size: 55px;
  position: relative;
  animation: slideright 1s 1;
  -webkit-animation: slideright 1s 1;
  -moz-animation: slideright 1s 1;
  margin-top: 12%;
}

@keyframes slideright {
  from {
    left: -1200px;
  }
  to {
    left: 0px;
  }
}

span {
  color: rgb(250, 30, 87);
  font-weight: heavy;
  font-family: "Raleway", sans-serif;
}

html {
  scroll-behavior: smooth;
}

/* .software-title {
  color: white;
  font-family: "Raleway", sans-serif;
  font-weight: heavy;
  letter-spacing: 3px;
  text-align: center;
  font-size: 55px;
  position: relative;
  animation: slideright 1s 1;
  -webkit-animation: slideright 1s 1;
  -moz-animation: slideright 1s 1;
  margin-top: 20%;
} */

.sub-title {
  /* animation: slideright 1s 1;
  -webkit-animation: slideright 1s 1;
  -moz-animation: slideright 1s 1; */
  color: #f5f0f0;
  letter-spacing: 4px;
  font-family: "Fira Sans", sans-serif;
  text-align: center;
  font-size: 40px;
  /* margin-bottom: -80%;
   */
}

.titles {
  vertical-align: middle;
}

/* @keyframes slideleft {
    from {right: -1200px;}
    to {right: 0px;}
  } */

/* @media screen and (max-width: 2048px) {
    div.software-title {
      font-size: 300%;
    
    }

  } */

/*  ===================================================================================
    Apple MacBook Pro 15-inch (Early 2006 - Mid 2012) AND Apple MacBook Air 13-inch
    ===================================================================================  */

@media screen and (max-width: 1440px) {
  .software-title {
    font-size: 34px;
    /* margin-top: -200px; */
  }

  .main-nav-list li {
    font-size: 40px;
  }

  a {
    font-size: 32px;
  }

  .main-nav a {
    padding: 0.3em 4em;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 2px;
  }

  .sub-title {
    font-size: 42px;
  }

  span.web-developer {
    font-size: 100%;
  }
}

/*  ===================================================================================
    Apple MacBook Pro Retina 15-inch (Mid 2014)
    ===================================================================================  */

/*  ===================================================================================
    Apple Macbook Pro 13inch ( Mid 2009 - 2012 )
    ===================================================================================  */

@media screen and (max-width: 1280px) {
  .software-title {
    margin-top: 12%;
    color: white;
    letter-spacing: 1px;
    font-size: 32px;
  }

  .titles {
    vertical-align: middle;
    /* margin-top: 12%; */
  }

  .main-nav a {
    /* padding: .30em 3.60em; */
    font-size: 20px;
    width: 37%;
    height: 20px;
    text-align: center;
    align-items: center;
  }

  .sub-title {
    font-size: 38px;
  }

  div.sub-title {
    font-size: 185%;
  }

  span.web-developer {
    font-size: 100%;
  }
}

/*  ===================================================================================
    Ipad Pro
    ===================================================================================  */

@media screen and (max-width: 1024px) {
  .software-title {
    margin-top: 400px;
  }

  .main-nav a {
    padding: 0.25em 3.5em;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 2px;
  }

  /* .main-nav {
    margin-bottom: 400px;
  } */
  .sub-title {
    font-size: 28px;
  }
  .main-nav ul {
    /* margin-top: 40px; */
    /* margin-bottom: 420px; */
    /* text-align: center;    */
  }
  /* .web-developer{
    font-size: 48px;
    white-space: pre;
  } */
}

.software-title {
  font-size: 175%;
  /* margin-top: 400px; */
}

/*  ===================================================================================
    Ipad
    ===================================================================================  */

@media screen and (max-width: 768px) {
  /* .software-title {
    font-size: 175%;
    margin-top: 275px;
  } */

  .software-title {
    font-size: 175%;
    margin-top: 300px;
  }

  .web-developer {
    font-size: 48px;
    white-space: pre;
  }

  .main-nav a {
    padding: 0.25em 2.5em;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 2px;
  }

  .sub-title {
    font-size: 27px;
  }
}

/*  ===================================================================================
    Iphone 6/7/8 Plus
    ===================================================================================  */

@media screen and (max-width: 414px) {
  .software-title {
    /* margin-top: 250px; */
    font-size: 22px;
    letter-spacing: 0.5px;
    /* margin-top: 35%; */
  }

  .sub-title {
    font-size: 20px;
    letter-spacing: 0.5px;
  }

  div.sub-title {
    font-size: 20px;
  }

  .software-title {
    font-size: 18px;
    margin-top: 175px;
  }

  span.web-developer {
    font-size: 100%;
    white-space: pre;
  }
}

/*  ===================================================================================
    Iphone X
    ===================================================================================  */

@media screen and (max-width: 375px) {
  .main-nav a {
    padding: 0.25em 2.5em;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 2px;
  }
  .software-title {
    margin-top: 37%;
    font-size: 18px;
    letter-spacing: 0.5px;
  }

  .web-developer {
    font-size: 36px;
  }

  .sub-title {
    font-size: 20px;
    letter-spacing: 0.5px;
  }
}

footer {
  display: flex;
  background-color: rgb(23, 29, 28);
  color: white;
  height: 50px;
  justify-content: center;
  text-align: center;
  letter-spacing: 2px;
  font-family: "Fira Sans", sans-serif;
  font-size: 18px;
  background-repeat: no-repeat;
  background-size: 50%;
  align-items: center;   
}

div.svg {
  margin-bottom: 230px;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, -30px, 0);
  }
}

#VECTORS {
  animation: bounce 0.7s linear;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  transform-origin: center;

  /* animation: float .8s ease-in-out infinite alternate; */
}


/* @keyframes wave {
  from { transform: rotateY(-20deg);} 
    to { transform: rotateY(20deg);}
} 


#Hair {
  animation: wave 0.7s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  transform-origin: top;
} */


.copyright {
  font-size: 18px;
  color: white;
  display: block;
  border: solid black;
  width: 100%;
  background-color: black;
}



.social-list {
  margin: 0px 20px 0px 20px;
}


.icon-social {
  height: 30px;
  width: 30px;
}

.icon-social:hover {
  opacity: 0.7;
}


.social-box {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  padding: 20px;
}



/* Iphone X */
@media screen and (max-width: 375px) {
  footer {
    font-size: 12px;
  
  }
}

@media screen and (max-width: 1440px) {
  .svg {
    margin-right: 400px;
    margin-bottom: 130px;
  }

  .footer-div {
    width: 100%;
    font-size: 18px;
    color: white;
    display: block;
    border: solid black;
    width: 100%;
    background-color: black;
  }
}

@media screen and (max-width: 1280px) {
  .svg {
    margin-right: 600px;
    margin-bottom: 130px;
  }
}

@media screen and (max-width: 768px) {
  .svg {
    margin-right: 450px;
  }
  .icon-social {
    height: 15px;
    width: 15px;
  }
  .social-box {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    padding: 10px;
  }
}

@media screen and (max-width: 414px) {
  /* div.svg {
    display: none;
  } */
}

@media screen and (max-width: 375px) {
  /* .svg {
  width: 75%;
} */
}

.about-me-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  justify-content: center;
  border-radius: 4px;
  height: 100%;
  width: 100%;
}

.self-img {
  border: 2px solid black;
  border-radius: 50%;
  margin: 60px 60px;
}

.name {
  font-size: 28px;
  color: black;
  font-weight: 300;
  letter-spacing: 2px;
  font-family: "Montserrat", sans-serif;
  margin-top: 30px;
}

.fs-dev {
  font-size: 26px;
}

.title {
  margin-bottom: 20px;
  font-size: 30px;
  letter-spacing: 3px;
  font-family: "Raleway", sans-serif;
  color: black;
}

.fs-dev {
  font-size: 34px;
  font-family: "Montserrat", sans-serif;
}

.description {
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  font-family: "Helvetica", sans-serif;
  text-align: center;
  font-size: 28px;
  margin-top: -50px;
  line-height: 1.5;
  font-weight: 500;
}

div.description {
  width: 35%;
}

.bianca {
  font-size: 45px;
  font-family: "Montserrat", sans-serif;
  color: rgb(250, 30, 87);
}

/* Apple MacBook Pro 15-inch (Early 2006 - Mid 2012) AND Apple MacBook Air 13-inch */
@media screen and (max-width: 1440px) {

  div.title {
    font-size: 25px;
  }

  .self-img {
    border: 2px solid black;
    border-radius: 50%;
    margin-top: 20px;
  }

  div.description {
    width: 50%;
    letter-spacing: 1px;
    font-family: "Fira Sans", sans-serif;
    font-size: 22px;
    margin-top: -10px;
  }

  .title {
    margin-bottom: 20px;
    margin-top: -40px;
    font-size: 26px;
    letter-spacing: 3px;
    font-family: "Raleway", sans-serif;
    color: black;
  }

  .description {
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-family: "Fira Sans", sans-serif;
    text-align: center;
    font-size: 24px;
    margin-top: -50px;
    line-height: 1.5;
    font-weight: 500;
  }

}

/* @media screen and (max-width: 1366px) {

  .self-img {
    border: 2px solid black;
    border-radius: 50%;
    margin-top: -10px;
    margin-bottom: 25px;
  }

  .title {
    margin-bottom: 20px;
    margin-top: -40px;
    font-size: 25px;
    letter-spacing: 3px; 
    font-family: 'Raleway', sans-serif; 
    color: black;
  }
  
  .description {
    width: 50%;
    letter-spacing: 1px; 
    font-family: 'Fira Sans', sans-serif;
    font-size: 21px;
    margin-top: -10px;
    line-height: 1.25;
    
  }
} */

/* Apple Macbook Pro 13inch (Mid 2009 - Mid 2012) */
@media screen and (max-width: 1280px), screen and (max-height: 800px) {

   div.description {
    max-width: 50%;
    font-size: 24px;
  }

  .title {
    margin-bottom: 20px;
    font-size: 24px;
  }

  .description {
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-family: "Fira Sans", sans-serif;
    text-align: center;
    font-size: 20px;
    margin-top: -50px;
    line-height: 1.5;
    font-weight: 500;
  }
}

/* Ipad Pro */
@media screen and (max-width: 1024px) {

  .title {
    margin-bottom: 20px;
    margin-top: -40px;
    font-size: 22px;
    letter-spacing: 3px;
  }

  .self-img {
    border: 2px solid black;
    border-radius: 50%;
    margin-top: -10px;
    margin-bottom: 25px;
  }

  div.description {
    width: 70%;
    letter-spacing: 1px;
    font-family: "Fira Sans", sans-serif;
    font-size: 26px;
    line-height: 1.25;
  }

  .bianca {
    font-size: 50px;
  }

  .description {
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-family: "Fira Sans", sans-serif;
    text-align: center;
    font-size: 20px;
    margin-top: -50px;
    line-height: 1.5;
    font-weight: 500;
  }
}

/* Ipad */
@media screen and (max-width: 768px) {

div.description {
  width: 75%;
}

  /* .name {
    margin-top: -900px;
  } */

  /* .bianca {
    font-size: 34px;
    margin-top: -1000px;
  }  */

  /* .name {
    font-size: 20px;

    margin-bottom: 10px;
    margin-top: -1000px;
  }

  div.name {
    margin-top: -800px;
  }
  .bianca {
    font-size: 34px;
    margin-top: -1000px;
  } 

  .title {
    margin-bottom: 20px;
    margin-top: -40px;
    font-size: 22px;
    letter-spacing: .5px; 
  }
  .description {
    width: 50%;
    letter-spacing: 1px; 
    /* font-family: 'Raleway', sans-serif; */
  /* font-size: 21px;
    margin-top: -10px;
    line-height: 1.25; */
  /* margin-bottom: 175px; */
}

/* Iphone 6/7/8 Plus */
@media screen and (max-width: 414px) {

  .title {
    margin-bottom: 20px;
    margin-top: -40px;
    font-size: 16px;
    letter-spacing: 0.2px;
    text-align: center;
    width: 75%;
  }
  .self-img {
    border: 2px solid black;
    border-radius: 50%;
    margin-top: -10px;
    margin-bottom: 25px;
    height: 25cd ,..0px;
    width: 200px;
    align-items: center;

  }
  div.description {
    width: 75%;
    letter-spacing: 1px;
    font-size: 18px;
    line-height: 1;
  }

  .bianca {
    font-size: 40px;
  }

  .name {
    font-size: 22px;
  }

  div.title {
    font-size: 20px;
    margin-top: -10px;
  }

  .fs-dev {
    font-size: 28px;
  }

  .description {
    justify-content: center;
    text-align: left;
    letter-spacing: 1px;
    font-family: "Fira Sans", sans-serif;
    font-size: 18px;
    margin-top: -50px;
    line-height: 1.5;
    font-weight: 500;
  }


  .social-box {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    padding: 0px;
  }

  .social {
    display: flex;
    object-fit: contain;
    max-width: 25%;
  }

  .social-list {
    margin: 0px 0px 0px 0px;
  }


}

/* Iphone X */
@media screen and (max-width: 375px) {

  .title {
    margin-bottom: 20px;
    margin-top: -40px;
    font-size: 18px;
    letter-spacing: 0.5px;
  }
  div.description {
    width: 85%;
    letter-spacing: 1px;
    font-size: 16px;
    line-height: 1.25;
  }

  .bianca {
    font-size: 40px;
  }

  .name {
    font-size: 22px;
  }

  div.title {
    font-size: 20px;
    margin-top: -10px;
  }

  .fs-dev {
    font-size: 28px;
  }
}

/* Iphone 5/SE */
@media screen and (max-width: 320px) {

  .bianca {
    font-size: 33px;
  }

  .name {
    font-size: 20px;
  }
}
/* 
  .title {
    margin-bottom: 20px;
    margin-top: -40px;
    font-size: 12px;
    letter-spacing: 0.5px;
  }
  div.description {
    width: 75%;
    letter-spacing: 1px;
    font-size: 16px;
    line-height: 1.25;
  } */


.thanks {
  font-size: 24px;
  letter-spacing: 2px;
  font-family: "Raleway", sans-serif;
  margin: 50px 50px;
  justify-content: center;
  text-align: center;
  text-shadow: 0 22px 22px rgb(218, 139, 139);
}

.list {
  display: flex;
  object-fit: contain;
  max-width: 100%;
}


.contact-me-span {
  font-size: 42px;
  color: rgb(250, 30, 87);
  font-weight: bold;
  letter-spacing: 2px;
  font-family: "Raleway", sans-serif;
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1),
    0px 18px 23px rgba(0, 0, 0, 0.1);
}

span.asap-span {
  font-weight: bold;
  font-size: 32px;
  font-style: oblique;
  text-shadow: 0 22px 22px rgb(218, 139, 139);
}

span.anytime {
  font-size: 30px;
  margin-right: 120px;
  color: black;
  font-style: oblique;
}

span.anywhere-span {
  font-size: 30px;
  font-style: oblique;
}

.contact-aa {
  text-align: center;
}

.contact-container {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  margin: 0 auto;
  justify-content: center;
  border-radius: 4px;
  height: 1300px;
  align-content: flex-start;
  max-width: 100%;
  background: url(https://i.imgur.com/YJGJ53d.jpg);
  background-size: 100%;
  background-size: cover;
}

.link-list {
  margin: 0px 60px 0px 60px;
}

.contact-box {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  padding: 20px;
}

@keyframes blink {
  50% {
    background: radial-gradient(ellipse, rgb(230, 54, 39) 5%, transparent 10%),
      rgba(243, 223, 223, 0.8);
  }
}

div.contact-form {
  width: 70%;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 50%;
  
}

.contact-form {
  border: solid black;
  padding: 40px;
  padding-top: 30px;
  border-radius: 20px;
  box-shadow: 0 0 17px 6px rgba(0, 0, 0, 1);
  animation: blink 1s linear infinite;
  -webkit-animation: blink 1.25s infinite;
  -moz-animation: blink 1.25s infinite;
  background: radial-gradient(ellipse, white 5%, transparent 10%),
    rgba(243, 223, 223, 0.7);
}

.pin-drop {
  width: 15%;
}

.icon-email {
  height: 65px;
  width: 60px;
}

.icon-email:hover {
  opacity: 0.7;
}

.icon-linkedin {
  height: 55px;
  width: 60px;
}

.icon-linkedin:hover {
  opacity: 0.7;
}

.icon-tele {
  height: 65px;
  width: 65px;
  border-radius: 50%;
}

.icon-tele:hover {
  opacity: 0.7;
}

.icon-github {
  height: 65px;
  width: 65px;
}

.icon-github:hover {
  opacity: 0.7;
}

.icon-resume {
  height: 70px;
  width: 75px;
}

.icon-resume:hover {
  opacity: 0.7;
}

.contact-box a:link {
  color: black;
}
.contact-box a:visited {
  color: #fa1e57;
}

div.svg {
  margin-left: 400px;
}

/* Apple MacBook Pro 15-inch (Early 2006 - Mid 2012) AND Apple MacBook Air 13-inch */
@media screen and (max-width: 1440px) {
  div.svg {
    margin-right: 400px;
    z-index: 1;
  }

  /* .contact-box {
    object-fit: contain;
    max-width: 75%;
  } */

  /* .contact-form {
    max-width: 70%;
  } */

  /* .contact-container {
    border-radius: 4px;
    height: 1300px;
    height: 1300px;
    max-width: 100%;
    background-size: 75%;
    background-size: cover;
  } */

  .get-back-span {
    color: black;
    font-size: 24px;
  }

  .asap-span {
    font-weight: bold;
    font-size: 32px;
    font-style: oblique;
    text-shadow: 0 22px 22px rgb(218, 139, 139);
  }

  div.contact-container {
    height: 950px;
    object-fit: contain;
  }

  .contact-me-span {
    font-size: 34px;
  }

  .anytime {
    font-size: 30px;
    color: black;
  }

  span.anywhere-span {
    font-size: 30px;
  }

  .pin-drop {
    height: 100px;
    width: 100px;
    margin-top: -40px;
    margin-left: 20px;
  }
}

/* Macbook Pro 13inch (Mid 2009 - 2012) */
@media screen and (max-width: 1280px) {
  /* .contact-form {
    width: 70%;
  }  */
    /* .contact-box {
    max-width: 70%;
    object-fit: contain;
  }  */
}

/* Ipad Pro */
@media screen and (max-width: 1024px) {
  /* .contact-container {
    border-radius: 4px;
    height: 1800px;
    max-width: 100%;
    background-size: 75%;
    background-size: cover;
  } */

  /* div.contact-box {
    max-width: 70%;
    object-fit: contain;
  } */

  .link-list {
    margin: 0px 30px 0px 30px;
  }
  
  ul.list {
    margin-top: 20px;
    padding: 0px;
  }

  .get-back-span {
    color: black;
    font-size: 22px;
  }

  .asap-span {
    font-weight: bold;
    font-size: 22px;
    font-style: oblique;
    text-shadow: 0 22px 22px rgb(218, 139, 139);
  }

  /* div.contact-container {
    height: 850px;
  } */

  /* ul.contact-box {
    margin-top: -40px;
  } */

  .contact-me-span {
    font-size: 32px;
  }

  .anytime {
    font-size: 26px;
    color: black;
  }

  .pin-drop {
    height: 80px;
    width: 80px;
    margin-top: -40px;
    margin-left: 20px;
  }
}

/* Ipad */
@media screen and (max-width: 768px) {
  .asap-span {
    font-size: 40px;
  }
  .link-list {
    margin: 0px 25px 0px 25px;
  }
  
  ul.list {
    margin-top: 20px;
    padding: 0px;
  }

  img.pin-drop {
    margin-top: 10px;
  }
}
@media screen and (max-width: 414px) {
  div.contact-form {
    width: 70%;
    border-radius: 90px;
    height: 50%;
  }

  span.anytime {
    font-size: 30px;
    margin-right: 80px;
    color: black;
    font-style: oblique;
  }

  .link-list {
    margin: auto;
}

img.pin-drop {
  margin-top: 10px;
}
}
/* Iphone X */
@media screen and (max-width: 375px) {
  span.anytime {
    font-size: 28px;
    color: black;
    font-style: oblique;
  }

  span.anywhere-span {
    font-size: 28px;
    font-style: oblique;
  }

  .get-back-span {
    color: black;
    font-size: 16px;
  }

  span.asap-span {
    font-weight: bold;
    font-size: 24px;
    font-style: oblique;
    text-shadow: 0 22px 22px rgb(218, 139, 139);
  }

  img.pin-drop {
    margin-top: 10px;
  }

  .contact-me-span {
    font-size: 26px;
  }

  .link-list {
    margin: auto;
  }
}

/* Iphone X */
@media screen and (max-width: 320px) {
  span.anytime {
    font-size: 26px;
    color: black;
    font-style: oblique;
    margin: auto;
    padding: 10px;
  }

  span.anywhere-span {
    font-size: 28px;
    font-style: oblique;
    padding: 10px;

  }


  img.pin-drop {
    margin-top: 10px;
  }

  .contact-me-span {
    font-size: 26px;
  }

  .link-list {
    width: 75%;
  
  }

  div.contact-box {
    padding: 0px;
  }
}

.skills-tools-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 25px;
  justify-content: center;
  border-radius: 4px;
  justify-content: space-around;
  box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
  max-width: 100%;
  width: 100%;
  margin-top: 40px;
  /* color: linear-gradient(to bottom, rgb(75, 209, 243), blue); */
}

.main-skills {
  margin-top: 0 auto;
}

.about-skill-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  justify-content: space-evenly;
  /* max-width: 70%; */
}

.about-skill-list img {
  display: flex;
  list-style: none;
  align-content: space-around;
  justify-content: space-between;
  padding: 20px 10px;
  max-width: 100%;
  justify-content: center;
}

.skills-tools-container li:hover {
  display: flex;
  justify-content: space-between;
}

li {
  display: inline;
  text-align: center;
  color: #616161;
}

.icon-html {
  height: 78px;
  width: 85px;
}

.icon-css {
  height: 72px;
  width: 75px;
}

.icon-js {
  height: 72px;
  width: 55px;
}

.icon-react {
  height: 70px;
  width: 60px;
}

.icon-redux {
  height: 68px;
  width: 58px;
}

.icon-node {
  height: 80px;
  width: 110px;
}

.icon-vscode {
  height: 70px;
  width: 85px;
}

.icon-ruby {
  height: 65px;
  width: 65px;
}

.icon-rails {
  height: 100px;
  width: 110px;
}

.icon-sql {
  height: 85px;
  width: 110px;
}

.icon-mongo {
  width: 125px;
  height: 125px;
  /* border-radius: 60%; */
}

.icon-postman {
  width: 75px;
}

.icon-express {
  width: 75px;
}

.icon-storybook {
  width: 65px;
  height: 65px;
}

.icon-figma {
  width: 65px;
  height: 65px;
}

.icon-photoshop {
  width: 95px;
  height: 90px;
}

.icon-adobe {
  width: 70px;
  height: 60px;
}

.icon-stripe {
  width: 60px;
  height: 60px;
}

.icon-firebase {
  width: 95px;
  height: 90px;
  margin-top: -10px;
}

.icon-netlify {
  margin-top: 20px;
  width: 100px;
}

.icon-surge {
  height: 75px;
  width: 75px;
}

.icon-heroku {
  height: 60px;
  width: 125px;
}

.icon-wordpress {
  height: 100px;
  width: 100px;
  margin-top: -15px;
}

.skills-tools-title {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 3px;
  font-family: "Raleway", sans-serif;
  justify-content: center;
  list-style: none;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

/* Apple MacBook Pro 15-inch (Early 2006 - Mid 2012) AND Apple MacBook Air 13-inch */
@media screen and (max-width: 1440px), screen and (max-height: 900px) {
  .skills-tools-container {
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    justify-content: center;
    border-radius: 4px;
    flex-wrap: wrap;
    margin-bottom: 125px;
    justify-content: space-around;
    box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
    max-width: 100%;
    width: 100%;
  }

  .about-skill-list img {
    display: flex;
    list-style: none;
    align-content: space-around;
    justify-content: space-between;
    padding: 20px 10px 3px 10px;
    max-width: 75%;
    justify-content: center;
    margin-right: 50px;
  }

  li {
    display: inline;
    text-align: center;
    color: #616161;
    font-size: 12px;
  }
}

@media screen and (max-width: 1366px) {
  .skills-tools-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 25px;
    justify-content: center;
    border-radius: 4px;
    flex-wrap: wrap;
    margin-bottom: 80px;
    justify-content: space-around;
    box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
    max-width: 100%;
    width: 100%;
  }

  .about-skill-list img {
    display: flex;
    list-style: none;
    align-content: space-around;
    justify-content: space-between;
    padding: 20px 10px 3px 10px;
    max-width: 75%;
    justify-content: center;
    margin-right: 50px;
  }

  li {
    display: inline;
    text-align: center;
    color: #616161;
    font-size: 11px;
  }

  .skills-tools-title {
    font-size: 21px;
  }
}

/* Apple Macbook Pro 13inch (Mid 2009 - 2012) */
@media screen and (max-width: 1280px), screen and (max-height: 800px) {
  div.skills-tools-container {
    height: 450px;
  }
}

/* Ipad Pro */
@media screen and (max-width: 1024px), screen and (max-height: 1366px) {
  .skills-tools-container {
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    justify-content: center;
    border-radius: 4px;
    margin-bottom: 80px;
    justify-content: space-around;
    box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
    max-width: 100%;
  }

  .about-skill-list img {
    list-style: none;
    align-content: space-around;
    justify-content: space-between;
    padding: 20px 10px 3px 10px;
    max-width: 100%;
    justify-content: center;
    margin-right: 50px;
  }

  li {
    display: inline;
    text-align: center;
    color: #616161;
    font-size: 10px;
  }

  .skills-tools-title {
    font-size: 18px;
  }
}

/* Ipad */
@media screen and (max-width: 768px), screen and (max-height: 1024px) {
  .skills-tools-container {
    align-items: center;
    padding-bottom: 25px;
    justify-content: center;
    border-radius: 4px;
    margin-bottom: 80px;
    justify-content: space-around;
    box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
    max-width: 100%;
    height: 100%;
  }

  div.skills-tools-container {
    height: 100%;
  }
}

/* Iphone 6/7/8 Plus */
@media screen and (max-width: 414px) {
  .skills-tools-container {
    align-items: center;
    padding-bottom: 25px;
    justify-content: center;
    border-radius: 4px;
    margin-bottom: 80px;
    justify-content: space-around;
    box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
    max-width: 100%;
  }

  .skills-tools-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 25px;
    justify-content: center;
    border-radius: 4px;
    justify-content: space-around;
    box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
    max-width: 100%;
    width: 100%;
    margin-top: 40px;
  }

  .icon-html {
    height: 58px;
    width: 65px;
  }

  .icon-css {
    height: 56px;
    width: 60px;
  }

  .icon-js {
    height: 58px;
    width: 42px;
  }

  .icon-react {
    height: 52px;
    width: 40px;
  }

  .icon-node {
    height: 60px;
    width: 90px;
  }

  .icon-ruby {
    height: 45px;
    width: 45px;
  }

  .icon-rails {
    height: 80px;
    width: 90px;
  }

  .icon-sql {
    height: 65px;
    width: 90px;
  }

  .icon-mongo {
    width: 75px;
    border-radius: 80%;
  }

  .icon-postman {
    width: 55px;
  }

  .icon-express {
    width: 55px;
  }

  .icon-storybook {
    width: 45px;
    height: 45px;
  }

  .icon-photoshop {
    width: 65px;
    height: 60px;
  }

  .icon-adobe {
    width: 50px;
    height: 40px;
  }

  .icon-netlify {
    margin-top: 20px;
    width: 80px;
  }

  .icon-surge {
    height: 55px;
    width: 55px;
  }

  .icon-heroku {
    height: 40px;
    width: 105px;
  }
}

/* Iphone X */
@media screen and (max-width: 375px), screen and (max-height: 812px) {
  .skills-tools-container {
    align-items: center;
    padding-bottom: 25px;
    justify-content: center;
    border-radius: 4px;
    margin-bottom: 50px;
    justify-content: space-around;
    box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
    max-width: 100%;
  }

  .about-skill-list img {
    list-style: none;
    align-content: space-around;
    justify-content: space-between;
    padding: 20px 10px 3px 10px;
    max-width: 100%;
    justify-content: center;
    margin-right: 50px;
  }
}

