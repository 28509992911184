.skills-tools-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 25px;
  justify-content: center;
  border-radius: 4px;
  justify-content: space-around;
  box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
  max-width: 100%;
  width: 100%;
  margin-top: 40px;
  /* color: linear-gradient(to bottom, rgb(75, 209, 243), blue); */
}

.main-skills {
  margin-top: 0 auto;
}

.about-skill-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  justify-content: space-evenly;
  /* max-width: 70%; */
}

.about-skill-list img {
  display: flex;
  list-style: none;
  align-content: space-around;
  justify-content: space-between;
  padding: 20px 10px;
  max-width: 100%;
  justify-content: center;
}

.skills-tools-container li:hover {
  display: flex;
  justify-content: space-between;
}

li {
  display: inline;
  text-align: center;
  color: #616161;
}

.icon-html {
  height: 78px;
  width: 85px;
}

.icon-css {
  height: 72px;
  width: 75px;
}

.icon-js {
  height: 72px;
  width: 55px;
}

.icon-react {
  height: 70px;
  width: 60px;
}

.icon-redux {
  height: 68px;
  width: 58px;
}

.icon-node {
  height: 80px;
  width: 110px;
}

.icon-vscode {
  height: 70px;
  width: 85px;
}

.icon-ruby {
  height: 65px;
  width: 65px;
}

.icon-rails {
  height: 100px;
  width: 110px;
}

.icon-sql {
  height: 85px;
  width: 110px;
}

.icon-mongo {
  width: 125px;
  height: 125px;
  /* border-radius: 60%; */
}

.icon-postman {
  width: 75px;
}

.icon-express {
  width: 75px;
}

.icon-storybook {
  width: 65px;
  height: 65px;
}

.icon-figma {
  width: 65px;
  height: 65px;
}

.icon-photoshop {
  width: 95px;
  height: 90px;
}

.icon-adobe {
  width: 70px;
  height: 60px;
}

.icon-stripe {
  width: 60px;
  height: 60px;
}

.icon-firebase {
  width: 95px;
  height: 90px;
  margin-top: -10px;
}

.icon-netlify {
  margin-top: 20px;
  width: 100px;
}

.icon-surge {
  height: 75px;
  width: 75px;
}

.icon-heroku {
  height: 60px;
  width: 125px;
}

.icon-wordpress {
  height: 100px;
  width: 100px;
  margin-top: -15px;
}

.skills-tools-title {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 3px;
  font-family: "Raleway", sans-serif;
  justify-content: center;
  list-style: none;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

/* Apple MacBook Pro 15-inch (Early 2006 - Mid 2012) AND Apple MacBook Air 13-inch */
@media screen and (max-width: 1440px), screen and (max-height: 900px) {
  .skills-tools-container {
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    justify-content: center;
    border-radius: 4px;
    flex-wrap: wrap;
    margin-bottom: 125px;
    justify-content: space-around;
    box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
    max-width: 100%;
    width: 100%;
  }

  .about-skill-list img {
    display: flex;
    list-style: none;
    align-content: space-around;
    justify-content: space-between;
    padding: 20px 10px 3px 10px;
    max-width: 75%;
    justify-content: center;
    margin-right: 50px;
  }

  li {
    display: inline;
    text-align: center;
    color: #616161;
    font-size: 12px;
  }
}

@media screen and (max-width: 1366px) {
  .skills-tools-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 25px;
    justify-content: center;
    border-radius: 4px;
    flex-wrap: wrap;
    margin-bottom: 80px;
    justify-content: space-around;
    box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
    max-width: 100%;
    width: 100%;
  }

  .about-skill-list img {
    display: flex;
    list-style: none;
    align-content: space-around;
    justify-content: space-between;
    padding: 20px 10px 3px 10px;
    max-width: 75%;
    justify-content: center;
    margin-right: 50px;
  }

  li {
    display: inline;
    text-align: center;
    color: #616161;
    font-size: 11px;
  }

  .skills-tools-title {
    font-size: 21px;
  }
}

/* Apple Macbook Pro 13inch (Mid 2009 - 2012) */
@media screen and (max-width: 1280px), screen and (max-height: 800px) {
  div.skills-tools-container {
    height: 450px;
  }
}

/* Ipad Pro */
@media screen and (max-width: 1024px), screen and (max-height: 1366px) {
  .skills-tools-container {
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    justify-content: center;
    border-radius: 4px;
    margin-bottom: 80px;
    justify-content: space-around;
    box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
    max-width: 100%;
  }

  .about-skill-list img {
    list-style: none;
    align-content: space-around;
    justify-content: space-between;
    padding: 20px 10px 3px 10px;
    max-width: 100%;
    justify-content: center;
    margin-right: 50px;
  }

  li {
    display: inline;
    text-align: center;
    color: #616161;
    font-size: 10px;
  }

  .skills-tools-title {
    font-size: 18px;
  }
}

/* Ipad */
@media screen and (max-width: 768px), screen and (max-height: 1024px) {
  .skills-tools-container {
    align-items: center;
    padding-bottom: 25px;
    justify-content: center;
    border-radius: 4px;
    margin-bottom: 80px;
    justify-content: space-around;
    box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
    max-width: 100%;
    height: 100%;
  }

  div.skills-tools-container {
    height: 100%;
  }
}

/* Iphone 6/7/8 Plus */
@media screen and (max-width: 414px) {
  .skills-tools-container {
    align-items: center;
    padding-bottom: 25px;
    justify-content: center;
    border-radius: 4px;
    margin-bottom: 80px;
    justify-content: space-around;
    box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
    max-width: 100%;
  }

  .skills-tools-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 25px;
    justify-content: center;
    border-radius: 4px;
    justify-content: space-around;
    box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
    max-width: 100%;
    width: 100%;
    margin-top: 40px;
  }

  .icon-html {
    height: 58px;
    width: 65px;
  }

  .icon-css {
    height: 56px;
    width: 60px;
  }

  .icon-js {
    height: 58px;
    width: 42px;
  }

  .icon-react {
    height: 52px;
    width: 40px;
  }

  .icon-node {
    height: 60px;
    width: 90px;
  }

  .icon-ruby {
    height: 45px;
    width: 45px;
  }

  .icon-rails {
    height: 80px;
    width: 90px;
  }

  .icon-sql {
    height: 65px;
    width: 90px;
  }

  .icon-mongo {
    width: 75px;
    border-radius: 80%;
  }

  .icon-postman {
    width: 55px;
  }

  .icon-express {
    width: 55px;
  }

  .icon-storybook {
    width: 45px;
    height: 45px;
  }

  .icon-photoshop {
    width: 65px;
    height: 60px;
  }

  .icon-adobe {
    width: 50px;
    height: 40px;
  }

  .icon-netlify {
    margin-top: 20px;
    width: 80px;
  }

  .icon-surge {
    height: 55px;
    width: 55px;
  }

  .icon-heroku {
    height: 40px;
    width: 105px;
  }
}

/* Iphone X */
@media screen and (max-width: 375px), screen and (max-height: 812px) {
  .skills-tools-container {
    align-items: center;
    padding-bottom: 25px;
    justify-content: center;
    border-radius: 4px;
    margin-bottom: 50px;
    justify-content: space-around;
    box-shadow: 0 0 8px 8px rgba(226, 218, 218, 0.5);
    max-width: 100%;
  }

  .about-skill-list img {
    list-style: none;
    align-content: space-around;
    justify-content: space-between;
    padding: 20px 10px 3px 10px;
    max-width: 100%;
    justify-content: center;
    margin-right: 50px;
  }
}
