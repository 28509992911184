.projects-name {
  font-size: 200%;
  font-weight: 100;
  letter-spacing: 3px;
  font-family: "Raleway", sans-serif;
  color: rgb(250, 30, 87);
  height: 0px;
  text-align: center;
  border: solid rgb(18, 67, 80);
  text-shadow: 0px 8px 6px rgba(0, 0, 0, 0.4), 0px 16px 26px rgba(0, 0, 0, 0.1),
    0px 28px 44px rgba(0, 0, 0, 0.1);
}

.project-title {
  font-size: 200%;
  font-weight: 300;
  letter-spacing: 3px;
  font-family: "Raleway", sans-serif;
  color: white;
}

.all-projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 40px;
  border: black;
  margin: 0 auto;
  height: auto;
}

.all-projects {
  background: url(https://i.imgur.com/8MHUS6E.jpg) no-repeat center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.project-img {
  display: flex;
  padding: 15px;
  margin: 0 45px;
  border: solid 2px black;
  height: 500px;
  width: 500px;
  background-color: rgb(80, 235, 235);
  border-radius: 50%;
  
}

.project-img-instacart {
  display: flex;
  padding: 15px;
  border: solid 2px black;
  height: 500px;
  width: 500px;
  background-color: rgb(80, 235, 235);
  border-radius: 50%;
}

a {
  text-decoration: none;
}

a.button {
  font-size: 120%;
}

.mm-project-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  justify-content: center;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
  /* background-color: rgba(245, 201, 201, 0.5); */
}

.mm-project-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.mm-project-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.mm-project-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}

.instabook-project-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 25%;
}

.amazon-project-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 25%;
}

.amazon-project-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.amazon-project-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.amazon-project-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}

.instabook-project-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.instabook-project-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.instabook-project-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}





.instacart-project-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
}

.instacart-project-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.instacart-project-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.instacart-project-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}




.restaurant-demo-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
}

.restaurant-demo-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.restaurant-demo-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.restaurant-demo-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}



.insurance-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
}

.insurance-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.insurance-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.insurance-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}



.chiro-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
}

.chiro-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.chiro-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.chiro-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}



.atny-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
}

.atny-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.atny-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.atny-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}




.portfolio-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
}

.portfolio-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.portfolio-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.portfolio-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}





.ins-landing-container {
  display: flex;
  margin: 200px;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 45px;
  border: 2px solid black;
  width: 25%;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  border-radius: 25%;
  background-color: rgba(0, 0, 0, 0.25);
}

.ins-landing-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.ins-landing-container:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(80, 235, 235);
}

.ins-landing-container:hover {
  box-shadow: 0 0 35px rgb(80, 235, 235);
  text-shadow: 0 0 15px rgb(80, 235, 235);
}







.project-description {
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  font-family: "Raleway", sans-serif;
  text-align: center;
  font-size: 20px;
  margin: 20px;
  color: white;
  line-height: 1.5;
  width: 75%;
}

.tech-description {
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  font-family: "Raleway", sans-serif;
  text-align: center;
  font-size: 18px;
  margin: 20px;
  color: white;
}

/* .buttons {
  display: flex;
  justify-content: center;
  width: 85%
};  */

.project-url {
  /* margin-right: 20px; */
  background-color: rgb(80, 235, 235);
  height: 50px;
  width: 175px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-url:hover {
  background-color: rgb(250, 30, 87);
}

.github-url {
  /* margin-left: 20px; */
  background-color: rgb(80, 235, 235);
  height: 50px;
  width: 175px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.github-url:hover {
  background-color: rgb(250, 30, 87);
}

.github-url,
.projecturl a:link {
  color: blue;
}
.github-url,
.projecturl a:visited {
  color: purple;
}

/* Apple MacBook Pro 15-inch (Early 2006 - Mid 2012) AND Apple MacBook Air 13-inch */
@media screen and (max-width: 1440px), screen and (max-height: 900px) {
  /* .all-projects { 
    background: url(https://i.imgur.com/8MHUS6E.jpg) no-repeat center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  } */
}

/* Apple MacBook Pro 13-inch  */
@media screen and (max-width: 1280px) {
  .project-img {
    padding: 15px;
    margin: 0 45px;
    height: 450px;
    width: 450px;
  }

  .project-img-instacart {
    height: 450px;
    width: 450px;
  }

  .project-description {
    letter-spacing: 0.2px;
    font-size: 20px;
    margin: 20px;
    line-height: 1.2;
  }

  .tech-description {
    letter-spacing: 1px;
    font-size: 18px;
    margin: 20px;
    margin-bottom: -30px;
  }
}

/* Ipad Pro */
@media screen and (max-width: 1024px) {
  .project-img {
    height: 350px;
    width: 350px;
  }

  .project-img-instacart {
    height: 350px;
    width: 350px;
  }

  div.buttons {
    margin: auto;
  }
}

/* Ipad */
@media screen and (max-width: 768px) /*, screen and (max-height: 1024px) */ {
  .all-projects {
    background: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .instacart-project-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;
    background-color: rgba(33, 175, 194, 0.25);
  }

  .mm-project-container {
    border: 2px solid rgb(83, 199, 219);
    width: 53%;
    background-color: rgba(33, 175, 194, 0.25);
    /* background-color: rgba(245, 201, 201, 0.5); */
  }

  .instabook-project-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;

    background-color: rgba(33, 175, 194, 0.25);
  }

  .portfolio-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;

    background-color: rgba(33, 175, 194, 0.25);
  }

  .chiro-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;

    background-color: rgba(33, 175, 194, 0.25);
  }

  .atny-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;

    background-color: rgba(33, 175, 194, 0.25);
  }

  .amazon-project-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;
    background-color: rgba(33, 175, 194, 0.25);
  }

  .restaurant-demo-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;
    background-color: rgba(33, 175, 194, 0.25);
  }

  .insurance-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;
    background-color: rgba(33, 175, 194, 0.25);
  }

  .ins-landing-container {
    border: 2px solid rgb(83, 199, 219);
    width: 55%;
    background-color: rgba(33, 175, 194, 0.25);
  }

  .project-title {
    font-size: 200%;
    font-weight: 300;
    letter-spacing: 3px;
    font-family: "Raleway", sans-serif;
    color: white;
    margin-top: 15px;
  }

  .tech-description {
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-size: 18px;
    margin: 20px;
    margin-bottom: -30px;
  }

  .project-description {
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-size: 20px;
    margin: 15px;
    margin-bottom: -10px;
    line-height: 1.2;
  }

  .project-img {
    height: 350px;
    width: 350px;
  }

  .project-img-instacart {
    height: 350px;
    width: 350px;
  }
}

/* Iphone 6/7/8 Plus */
@media screen and (max-width: 414px), /* screen and (max-height: 736px) */ {
  .all-projects {
    background: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .buttons {
    width: 200px;
  }
  .buttons {
    margin-top: 10px;
  }

  .project-url {
    margin: auto;
    margin-top: 60px;
    margin-bottom: 20px;
  }

  .github-url {
    margin: auto;
  }

  .img-container {
  
  }

  .project-description {
    justify-content: center;
    text-align: left;
    letter-spacing: 1px;
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    margin: 20px;
    color: white;
    line-height: 1.5;
    width: 75%;
  }

  .projects-name {
    font-size: 24px;
    font-weight: 100;
    letter-spacing: 3px;
    font-family: "Raleway", sans-serif;
    color: rgb(250, 30, 87);
    height: 0px;
    text-align: center;
    border: solid rgb(18, 67, 80);
    text-shadow: 0px 8px 6px rgba(0, 0, 0, 0.4), 0px 16px 26px rgba(0, 0, 0, 0.1),
      0px 28px 44px rgba(0, 0, 0, 0.1);
  }

  .project-title {
    font-size: 22px;
    font-weight: 300;
    letter-spacing: 3px;
    font-family: "Raleway", sans-serif;
    color: white;
  }
}

/* Iphone X */
@media screen and (max-width: 375px) /*, screen and (max-height: 812px) */ {

  .project-img {
    height: 250px;
    width: 250px;
  }

  .project-img-instacart {
    height: 250px;
    width: 250px;
  }

  .project-description {
    justify-content: center;
    text-align: left;
    letter-spacing: 1px;
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    margin: 20px;
    color: white;
    line-height: 1.5;
    width: 75%;
  }

  .tech-description {
    font-size: 16px;
  }

  img.project-img {
    margin: 0px;
  }

  div.buttons {
    margin: auto;
  }
}


/* Iphone 5/SE */
@media screen and (max-width: 320px) {

  .projects-name {
    font-size: 25px;
  }

  /* div.buttons {
    
  } */

  .project-title {
    font-size: 22px;

  }



  .project-url {
    margin-right: 30px;
  }

  .github-url {
    margin-right: 30px;
  }
}
