.container {
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  height: 100vh;
  justify-content: space-around;
}

.overlay video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  object-position: center;
  object-fit: cover;
}

.main-nav-list {
  padding: 0px;
}

.main-nav ul {
  margin-top: 0px;
  text-align: center;
}

.main-nav li {
  display: inline;
}

.main-nav a {
  display: inline-block;
  padding: 0.3em 8em;
  width: 35%;
  color: white;
  justify-content: space-between;
  font-size: 27px;
  font-weight: 300;
  letter-spacing: 2px;
  font-family: "Raleway", sans-serif;
  justify-content: space-evenly;
  position: static;
  text-decoration: none;
  box-shadow: 0px 12px 10px #26c8ce;
  border-radius: 10px;
}

li a:hover {
  color: rgb(250, 30, 87);
  transition: all 0.3s ease;
}

li a:active {
  background-color: rgba(38, 200, 206, 0.25);
  transform: translateY(15px);
}

.software-title {
  color: white;
  font-family: "Raleway", sans-serif;
  font-weight: heavy;
  letter-spacing: 3px;
  text-align: center;
  font-size: 55px;
  position: relative;
  animation: slideright 1s 1;
  -webkit-animation: slideright 1s 1;
  -moz-animation: slideright 1s 1;
  margin-top: 12%;
}

@keyframes slideright {
  from {
    left: -1200px;
  }
  to {
    left: 0px;
  }
}

@-webkit-keyframes slideright {
  from {
    left: -1200px;
  }
  to {
    left: 0px;
  }
}

@-moz-keyframes slideright {
  from {
    left: -1200px;
  }
  to {
    left: 0px;
  }
}

span {
  color: rgb(250, 30, 87);
  font-weight: heavy;
  font-family: "Raleway", sans-serif;
}

html {
  scroll-behavior: smooth;
}

/* .software-title {
  color: white;
  font-family: "Raleway", sans-serif;
  font-weight: heavy;
  letter-spacing: 3px;
  text-align: center;
  font-size: 55px;
  position: relative;
  animation: slideright 1s 1;
  -webkit-animation: slideright 1s 1;
  -moz-animation: slideright 1s 1;
  margin-top: 20%;
} */

.sub-title {
  /* animation: slideright 1s 1;
  -webkit-animation: slideright 1s 1;
  -moz-animation: slideright 1s 1; */
  color: #f5f0f0;
  letter-spacing: 4px;
  font-family: "Fira Sans", sans-serif;
  text-align: center;
  font-size: 40px;
  /* margin-bottom: -80%;
   */
}

.titles {
  vertical-align: middle;
}

/* @keyframes slideleft {
    from {right: -1200px;}
    to {right: 0px;}
  } */

/* @media screen and (max-width: 2048px) {
    div.software-title {
      font-size: 300%;
    
    }

  } */

/*  ===================================================================================
    Apple MacBook Pro 15-inch (Early 2006 - Mid 2012) AND Apple MacBook Air 13-inch
    ===================================================================================  */

@media screen and (max-width: 1440px) {
  .software-title {
    font-size: 34px;
    /* margin-top: -200px; */
  }

  .main-nav-list li {
    font-size: 40px;
  }

  a {
    font-size: 32px;
  }

  .main-nav a {
    padding: 0.3em 4em;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 2px;
  }

  .sub-title {
    font-size: 42px;
  }

  span.web-developer {
    font-size: 100%;
  }
}

/*  ===================================================================================
    Apple MacBook Pro Retina 15-inch (Mid 2014)
    ===================================================================================  */

/*  ===================================================================================
    Apple Macbook Pro 13inch ( Mid 2009 - 2012 )
    ===================================================================================  */

@media screen and (max-width: 1280px) {
  .software-title {
    margin-top: 12%;
    color: white;
    letter-spacing: 1px;
    font-size: 32px;
  }

  .titles {
    vertical-align: middle;
    /* margin-top: 12%; */
  }

  .main-nav a {
    /* padding: .30em 3.60em; */
    font-size: 20px;
    width: 37%;
    height: 20px;
    text-align: center;
    align-items: center;
  }

  .sub-title {
    font-size: 38px;
  }

  div.sub-title {
    font-size: 185%;
  }

  span.web-developer {
    font-size: 100%;
  }
}

/*  ===================================================================================
    Ipad Pro
    ===================================================================================  */

@media screen and (max-width: 1024px) {
  .software-title {
    margin-top: 400px;
  }

  .main-nav a {
    padding: 0.25em 3.5em;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 2px;
  }

  /* .main-nav {
    margin-bottom: 400px;
  } */
  .sub-title {
    font-size: 28px;
  }
  .main-nav ul {
    /* margin-top: 40px; */
    /* margin-bottom: 420px; */
    /* text-align: center;    */
  }
  /* .web-developer{
    font-size: 48px;
    white-space: pre;
  } */
}

.software-title {
  font-size: 175%;
  /* margin-top: 400px; */
}

/*  ===================================================================================
    Ipad
    ===================================================================================  */

@media screen and (max-width: 768px) {
  /* .software-title {
    font-size: 175%;
    margin-top: 275px;
  } */

  .software-title {
    font-size: 175%;
    margin-top: 300px;
  }

  .web-developer {
    font-size: 48px;
    white-space: pre;
  }

  .main-nav a {
    padding: 0.25em 2.5em;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 2px;
  }

  .sub-title {
    font-size: 27px;
  }
}

/*  ===================================================================================
    Iphone 6/7/8 Plus
    ===================================================================================  */

@media screen and (max-width: 414px) {
  .software-title {
    /* margin-top: 250px; */
    font-size: 22px;
    letter-spacing: 0.5px;
    /* margin-top: 35%; */
  }

  .sub-title {
    font-size: 20px;
    letter-spacing: 0.5px;
  }

  div.sub-title {
    font-size: 20px;
  }

  .software-title {
    font-size: 18px;
    margin-top: 175px;
  }

  span.web-developer {
    font-size: 100%;
    white-space: pre;
  }
}

/*  ===================================================================================
    Iphone X
    ===================================================================================  */

@media screen and (max-width: 375px) {
  .main-nav a {
    padding: 0.25em 2.5em;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 2px;
  }
  .software-title {
    margin-top: 37%;
    font-size: 18px;
    letter-spacing: 0.5px;
  }

  .web-developer {
    font-size: 36px;
  }

  .sub-title {
    font-size: 20px;
    letter-spacing: 0.5px;
  }
}
