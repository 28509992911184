.about-me-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  justify-content: center;
  border-radius: 4px;
  height: 100%;
  width: 100%;
}

.self-img {
  border: 2px solid black;
  border-radius: 50%;
  margin: 60px 60px;
}

.name {
  font-size: 28px;
  color: black;
  font-weight: 300;
  letter-spacing: 2px;
  font-family: "Montserrat", sans-serif;
  margin-top: 30px;
}

.fs-dev {
  font-size: 26px;
}

.title {
  margin-bottom: 20px;
  font-size: 30px;
  letter-spacing: 3px;
  font-family: "Raleway", sans-serif;
  color: black;
}

.fs-dev {
  font-size: 34px;
  font-family: "Montserrat", sans-serif;
}

.description {
  justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  font-family: "Helvetica", sans-serif;
  text-align: center;
  font-size: 28px;
  margin-top: -50px;
  line-height: 1.5;
  font-weight: 500;
}

div.description {
  width: 35%;
}

.bianca {
  font-size: 45px;
  font-family: "Montserrat", sans-serif;
  color: rgb(250, 30, 87);
}

/* Apple MacBook Pro 15-inch (Early 2006 - Mid 2012) AND Apple MacBook Air 13-inch */
@media screen and (max-width: 1440px) {

  div.title {
    font-size: 25px;
  }

  .self-img {
    border: 2px solid black;
    border-radius: 50%;
    margin-top: 20px;
  }

  div.description {
    width: 50%;
    letter-spacing: 1px;
    font-family: "Fira Sans", sans-serif;
    font-size: 22px;
    margin-top: -10px;
  }

  .title {
    margin-bottom: 20px;
    margin-top: -40px;
    font-size: 26px;
    letter-spacing: 3px;
    font-family: "Raleway", sans-serif;
    color: black;
  }

  .description {
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-family: "Fira Sans", sans-serif;
    text-align: center;
    font-size: 24px;
    margin-top: -50px;
    line-height: 1.5;
    font-weight: 500;
  }

}

/* @media screen and (max-width: 1366px) {

  .self-img {
    border: 2px solid black;
    border-radius: 50%;
    margin-top: -10px;
    margin-bottom: 25px;
  }

  .title {
    margin-bottom: 20px;
    margin-top: -40px;
    font-size: 25px;
    letter-spacing: 3px; 
    font-family: 'Raleway', sans-serif; 
    color: black;
  }
  
  .description {
    width: 50%;
    letter-spacing: 1px; 
    font-family: 'Fira Sans', sans-serif;
    font-size: 21px;
    margin-top: -10px;
    line-height: 1.25;
    
  }
} */

/* Apple Macbook Pro 13inch (Mid 2009 - Mid 2012) */
@media screen and (max-width: 1280px), screen and (max-height: 800px) {

   div.description {
    max-width: 50%;
    font-size: 24px;
  }

  .title {
    margin-bottom: 20px;
    font-size: 24px;
  }

  .description {
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-family: "Fira Sans", sans-serif;
    text-align: center;
    font-size: 20px;
    margin-top: -50px;
    line-height: 1.5;
    font-weight: 500;
  }
}

/* Ipad Pro */
@media screen and (max-width: 1024px) {

  .title {
    margin-bottom: 20px;
    margin-top: -40px;
    font-size: 22px;
    letter-spacing: 3px;
  }

  .self-img {
    border: 2px solid black;
    border-radius: 50%;
    margin-top: -10px;
    margin-bottom: 25px;
  }

  div.description {
    width: 70%;
    letter-spacing: 1px;
    font-family: "Fira Sans", sans-serif;
    font-size: 26px;
    line-height: 1.25;
  }

  .bianca {
    font-size: 50px;
  }

  .description {
    justify-content: center;
    text-align: center;
    letter-spacing: 1px;
    font-family: "Fira Sans", sans-serif;
    text-align: center;
    font-size: 20px;
    margin-top: -50px;
    line-height: 1.5;
    font-weight: 500;
  }
}

/* Ipad */
@media screen and (max-width: 768px) {

div.description {
  width: 75%;
}

  /* .name {
    margin-top: -900px;
  } */

  /* .bianca {
    font-size: 34px;
    margin-top: -1000px;
  }  */

  /* .name {
    font-size: 20px;

    margin-bottom: 10px;
    margin-top: -1000px;
  }

  div.name {
    margin-top: -800px;
  }
  .bianca {
    font-size: 34px;
    margin-top: -1000px;
  } 

  .title {
    margin-bottom: 20px;
    margin-top: -40px;
    font-size: 22px;
    letter-spacing: .5px; 
  }
  .description {
    width: 50%;
    letter-spacing: 1px; 
    /* font-family: 'Raleway', sans-serif; */
  /* font-size: 21px;
    margin-top: -10px;
    line-height: 1.25; */
  /* margin-bottom: 175px; */
}

/* Iphone 6/7/8 Plus */
@media screen and (max-width: 414px) {

  .title {
    margin-bottom: 20px;
    margin-top: -40px;
    font-size: 16px;
    letter-spacing: 0.2px;
    text-align: center;
    width: 75%;
  }
  .self-img {
    border: 2px solid black;
    border-radius: 50%;
    margin-top: -10px;
    margin-bottom: 25px;
    height: 25cd ,..0px;
    width: 200px;
    align-items: center;

  }
  div.description {
    width: 75%;
    letter-spacing: 1px;
    font-size: 18px;
    line-height: 1;
  }

  .bianca {
    font-size: 40px;
  }

  .name {
    font-size: 22px;
  }

  div.title {
    font-size: 20px;
    margin-top: -10px;
  }

  .fs-dev {
    font-size: 28px;
  }

  .description {
    justify-content: center;
    text-align: left;
    letter-spacing: 1px;
    font-family: "Fira Sans", sans-serif;
    font-size: 18px;
    margin-top: -50px;
    line-height: 1.5;
    font-weight: 500;
  }


  .social-box {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    padding: 0px;
  }

  .social {
    display: flex;
    object-fit: contain;
    max-width: 25%;
  }

  .social-list {
    margin: 0px 0px 0px 0px;
  }


}

/* Iphone X */
@media screen and (max-width: 375px) {

  .title {
    margin-bottom: 20px;
    margin-top: -40px;
    font-size: 18px;
    letter-spacing: 0.5px;
  }
  div.description {
    width: 85%;
    letter-spacing: 1px;
    font-size: 16px;
    line-height: 1.25;
  }

  .bianca {
    font-size: 40px;
  }

  .name {
    font-size: 22px;
  }

  div.title {
    font-size: 20px;
    margin-top: -10px;
  }

  .fs-dev {
    font-size: 28px;
  }
}

/* Iphone 5/SE */
@media screen and (max-width: 320px) {

  .bianca {
    font-size: 33px;
  }

  .name {
    font-size: 20px;
  }
}
/* 
  .title {
    margin-bottom: 20px;
    margin-top: -40px;
    font-size: 12px;
    letter-spacing: 0.5px;
  }
  div.description {
    width: 75%;
    letter-spacing: 1px;
    font-size: 16px;
    line-height: 1.25;
  } */

